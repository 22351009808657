function aosWatch(id, add, action) {
  if (id) {
    if (add == true && typeof action === "function") {
      document.addEventListener("aos:in:" + id, ({ detail }) => {
        var details = []
        details.push("aos:in")
        details.push(Object.entries(detail))
        details.push(Object.entries(detail)[1][1]["data-aos-id"])
        details.push(Object.entries(detail)[1][1]["data-aos"])
        //console.log(details);
        // console.log(action);
        // console.log(typeof(action));
        action()
      })
    } else if (add == true) {
      document.addEventListener("aos:in:" + id, ({ detail }) => {
        var details = []
        details.push("aos:in")
        details.push(Object.entries(detail))
        details.push(Object.entries(detail)[1][1]["data-aos-id"])
        details.push(Object.entries(detail)[1][1]["data-aos"])
        //console.log(details);
      })
    }
  } else {
    document.addEventListener("aos:in:", ({ detail }) => {
      var details = []
      details.push("aos:in")
      details.push(Object.entries(detail))
      details.push(Object.entries(detail)[1][1]["data-aos-id"])
      details.push(Object.entries(detail)[1][1]["data-aos"])
      //console.log(details);
    })
  }
  document.addEventListener("aos:out", ({ detail }) => {
    var details = []
    details.push("aos:out")
    details.push(Object.entries(detail))
    details.push(Object.entries(detail)[1][1]["data-aos-id"])
    details.push(Object.entries(detail)[1][1]["data-aos"])
    //console.log(details);
  })
}

export default aosWatch
